define("discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", ["exports", "discourse/lib/constants", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _constants, _discourseI18n, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activityPubPostStatus = activityPubPostStatus;
  _exports.activityPubPostStatusText = activityPubPostStatusText;
  _exports.activityPubTopicActors = activityPubTopicActors;
  _exports.activityPubTopicStatus = activityPubTopicStatus;
  _exports.activityPubTopicStatusText = activityPubTopicStatusText;
  _exports.buildHandle = buildHandle;
  _exports.showStatusToUser = showStatusToUser;
  function getStatusDatetimeFormat() {
    let infoStatus = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return infoStatus ? (0, _discourseI18n.i18n)("dates.long_with_year") : (0, _discourseI18n.i18n)("dates.time_short_day");
  }
  function buildHandle(_ref) {
    let {
      actor,
      model,
      site
    } = _ref;
    if (!actor && !model || model && !site) {
      return undefined;
    } else {
      const username = actor ? actor.username : model.activity_pub_username;
      const domain = actor ? actor.domain : site.activity_pub_host;
      return `@${username}@${domain}`;
    }
  }
  function showStatusToUser(user, siteSettings) {
    if (!user || !siteSettings) {
      return false;
    }
    const groupIds = siteSettings.activity_pub_post_status_visibility_groups.split("|").map(Number);
    return user.groups.some(group => groupIds.includes(_constants.AUTO_GROUPS.everyone.id) || groupIds.includes(group.id));
  }
  function activityPubPostStatus(post) {
    let status;
    if (post.activity_pub_deleted_at) {
      status = "deleted";
    } else if (post.activity_pub_updated_at) {
      status = "updated";
    } else if (post.activity_pub_published_at) {
      status = post.activity_pub_local ? "published" : "published_remote";
    } else if (post.activity_pub_scheduled_at) {
      status = moment().isAfter(moment(post.activity_pub_scheduled_at)) ? "scheduled_past" : "scheduled";
    } else {
      status = "not_published";
    }
    return status;
  }
  function activityPubPostStatusText(post) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const status = opts.status || activityPubPostStatus(post);
    let i18nKey = opts.infoStatus ? "info_status" : "status";
    let i18nOpts = {
      actor: opts.postActor?.actor.handle
    };
    let datetime;
    if (status === "delivered") {
      datetime = post.activity_pub_delivered_at;
    } else if (status === "deleted") {
      datetime = post.activity_pub_deleted_at;
    } else if (status === "updated") {
      datetime = post.activity_pub_updated_at;
    } else if (status === "published") {
      datetime = post.activity_pub_published_at;
    } else if (status === "published_remote") {
      datetime = post.activity_pub_published_at;
    } else if (status.includes("scheduled")) {
      datetime = post.activity_pub_scheduled_at;
    }
    if (datetime) {
      i18nOpts.datetime = moment(datetime).format(getStatusDatetimeFormat(opts.infoStatus));
    }
    return (0, _discourseI18n.i18n)(`post.discourse_activity_pub.${i18nKey}.${status}`, i18nOpts);
  }
  function activityPubTopicStatus(topic) {
    let status;
    if (topic.activity_pub_deleted_at) {
      status = "deleted";
    } else if (topic.activity_pub_published_at) {
      status = topic.activity_pub_local ? "published" : "published_remote";
    } else if (topic.activity_pub_scheduled_at) {
      status = moment().isAfter(moment(topic.activity_pub_scheduled_at)) ? "scheduled_past" : "scheduled";
    } else {
      status = "not_published";
    }
    return status;
  }
  function activityPubTopicStatusText(topic) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const status = activityPubTopicStatus(topic);
    let i18nKey = opts.infoStatus ? "info_status" : "status";
    let i18nOpts = {
      actor: topic.activity_pub_actor.handle
    };
    let datetime;
    if (status === "deleted") {
      datetime = topic.activity_pub_deleted_at;
    } else if (status === "published") {
      datetime = topic.activity_pub_published_at;
    } else if (status === "published_remote") {
      datetime = topic.activity_pub_published_at;
    } else if (status.includes("scheduled")) {
      datetime = topic.activity_pub_scheduled_at;
    }
    if (datetime) {
      i18nOpts.datetime = moment(datetime).format(getStatusDatetimeFormat(opts.infoStatus));
    }
    return (0, _discourseI18n.i18n)(`topic.discourse_activity_pub.${i18nKey}.${status}`, i18nOpts);
  }
  function activityPubTopicActors(topic) {
    let result = [];
    if (topic.category_id) {
      let actor = _activityPubActor.default.findByModel({
        id: topic.category_id
      }, "category");
      if (actor) {
        result.push(actor);
      }
    }
    if (topic.tags) {
      topic.tags.forEach(tag => {
        let actor = _activityPubActor.default.findByModel(tag, "tag");
        if (actor) {
          result.push(actor);
        }
      });
    }
    return result;
  }
});